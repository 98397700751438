import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { settings } from '@/api/requests/settings';
import { useFiltersStore } from '@/stores/filters/filtersStore';
import {findIndex} from "lodash";

type AutoThemeTimeType = {
    timeDark: string;
    timeLight: string;
};
type UserSettingsType = { settings: { key: string; value: any }[] };
type TableSettingsType = { settings: { key: string; value: any }[]; filters: { key: string; value: any }[] };
type ParamsType = { tableId: string } | undefined;
type SettingsType = { key: string; value: any };

export const useCentralSettingsStore = defineStore('central-settings', () => {
    const userCentralSettings = ref<UserSettingsType>({ settings: [] });
    const tableCentralSettings = ref<TableSettingsType>({ settings: [], filters: [] });
    const isLoading = ref<boolean>(false);
    const filtersStore = useFiltersStore();
    const { filtersSlice } = storeToRefs(filtersStore);
    const isFiltersUpdated = ref(false);
    const isColumnsUpdate = ref(true);
    const isMounted = ref(false);
    const currentTableId = ref<string>();
    const automaticThemeTimes = ref<AutoThemeTimeType>();
    const individualColorsThemes = ref<{ [K: string]: any }>({ primaryColor: { h: 217, s: 91, l: 60 } });
    const isBackgroundImage = ref(false);

    const convertObjectToJSON = (dataObject: { key: string; value: string }[]) => {
        // Данная функция конвертирует приходящий на вход объект в json строку и возвращает данную строку обратно
        const newDataObject = dataObject;
        for (const dataItem in newDataObject) {
            if (typeof newDataObject[dataItem].value === 'object') newDataObject[dataItem].value = JSON.stringify(newDataObject[dataItem].value);
        }
        return newDataObject;
    };

    const prepearData = () => {
        // Данная функция преобразовывает данные настроек пользователя в строку json
        const data: { [K: string]: any } = userCentralSettings.value;
        data.settings = convertObjectToJSON(data.settings);
        return data;
    };

    function convertFromJson(str: string) {
        try {
            return JSON.parse(str);
        } catch (error) {
            return str;
        }
    }

    /** Данная функция получает настройки для таблицы из сохраненных данных
     * @param params - параметры передаваемые в запрос*/
    const getTableSettings = async (params: ParamsType) => {
        if (params) {
            let data;
            await settings
                .getTableSettings({ params: { query: params } })
                .then((response) => (data = convertFromJson(response.data.data.items)))
                .catch(() => {
                    console.warn(`WARNING! table settings not found: ${params.tableId}`);
                    postSettings(params);
                });
            return data;
        } else {
            throw `centralSettingsStore\\getTableSettings function error: params contain ${params} value. It doesn't available for request`;
        }
    };

    /** Данная функция получает настройки для пользователя из сохраненных данных*/
    const getUserSettings = async () => {
        let data;
        await settings
            .getUserSettings({ params: null })
            .then((response) => {
                const responseData = response.data.data;
                for (const key of Object.keys(responseData)) {
                    responseData[key] = convertFromJson(responseData[key]);
                }
                data = responseData;
            })
            .catch(() => {
                console.warn(`WARNING! user doesn't have settings`);
                postSettings();
            });
        return data;
    };

    /** Данная функция определяет какого типа настройки необходимо получить из сохраненных данных (для пользователя/таблицы)*/
    const getSettings = async (params?: ParamsType) => {
        isLoading.value = true;
        let data;
        if (params) data = await getTableSettings(params);
        else data = await getUserSettings();
        isLoading.value = false;
        return data;
    };

    /** Данная функция записывает впервые данные о настройках таблицы
     * @param params - параметры передаваемые в запросе*/
    const postTableSettings = async (params: ParamsType) => {
        if (params) {
            const requestData = JSON.stringify(tableCentralSettings.value);
            await settings.setTableSettings({ params: { data: requestData, query: params } }).catch(() => console.warn(`WARNING! table settings already exist: ${params.tableId}`));
        } else {
            throw `centralSettingsStore\\postTableSettings function error: params contain ${params} value. It doesn't available for request`;
        }
    };

    /** Данная функция записывает впервые данные о настройках пользователя*/
    const postUserSettings = async () => {
        const requestData = prepearData();
        await settings.setUserSettings({ params: { data: requestData.settings } }).catch(() => console.warn(`WARNING! user settings already exist`));
    };

    /** Данная функция определяет какой тип настроек надо впервые записать в сохраненные данные (пользовательские/таблицы)*/
    const postSettings = async (params?: ParamsType) => {
        isLoading.value = true;
        if (params) {
            await postTableSettings(params);
        } else {
            await postUserSettings();
        }
        isLoading.value = false;
    };

    /** Функция обновления настроек таблицы в сохраненных данных
     * @param params - параметры передаваемые в запрос*/
    const putTableSettings = async (params: ParamsType) => {
        if (params) {
            const requestData = JSON.stringify(tableCentralSettings.value);
            await settings.updateTableSettings({ params: { data: requestData, query: params } }).catch(() => console.warn(`WARNING! table settings not found: ${params.tableId}`));
        } else {
            throw `centralSettingsStore.ts\\putTableSettings function error: params contain ${params} value. It doesn't available for request`;
        }
    };

    /** Функция обновления настроек пользователя в сохраненных данных*/
    const putUserSettings = async () => {
        const requestData: { [K: string]: any } = prepearData();
        await settings.updateUserSettings({ params: { data: requestData } }).catch(() => console.warn(`WARNING! user doesn't have settings`));
    };

    /** Функция определяет какой тип настроек необходимо сохранить (пользовательские/таблицы)*/
    const putSettings = async (params?: ParamsType) => {
        // Функция определяет какой тип настроек необходимо сохранить (пользовательские/таблицы)
        isLoading.value = true;
        if (params) {
            await putTableSettings(params);
        } else {
            await putUserSettings();
        }
        isLoading.value = false;
    };

    /** Данная функция проверяет, есть ли в стейте свойство, которое мы собираемся записать.
     Если прилетает tableId мы делаем проверку для настроек таблицы иначе для пользователя */
    const propertyInSettings = (propertyObject: { key: string; value: any }, tableId: string | undefined) => {
        if (!tableId) {
            const propertyName: string = Object.keys(propertyObject)[0];
            return userCentralSettings.value.settings.findIndex((property: { key: string; value: string }) => property.key === propertyName);
        } else {
            const propertyName: string = propertyObject.key;
            if (propertyObject.key.includes('filter')) return tableCentralSettings.value.filters.findIndex((property: { key: string; value: string }) => property.key === propertyName);
            else return tableCentralSettings.value.settings.findIndex((property: { key: string; value: string }) => property.key === propertyName);
        }
    };

    const updateCentralSettings = async (newData: { [K: string]: any }, tableId?: string) => {
        // Данная функция выбирает что нам необходимо сделать, обновить данные или записать новые
        const params: ParamsType = tableId ? { tableId: tableId } : undefined;
        // Если в функцию прилетает tableId мы думаем для таблицы, иначе для настроек пользователя
        await saveCentralSettings(newData, tableId);
        await putSettings(params);
    };

    /** Данная функция записывает данные фильтров таблицы полученные из сохраненных данных
     * @param {string} tableId - Идентификатор текущей таблицы*/
    const fillFiltersValues = (tableId: string | undefined) => {
        if (tableId) {
            let filterData = undefined;
            if (tableCentralSettings.value.filters.length > 0) filterData = tableCentralSettings.value.filters.find((value: { key: string; value: string }) => value.key === `filters_${tableId}`);
            if (filterData && Object.keys(filterData.value).length > 0)
                //Было filtersSlice.value[data].filters, но data - неправильный tableId
                for (const data of Object.keys(filterData.value))
                    if (!'months years quarters'.includes(data)) {
                        console.log('data',data);
                        filtersSlice.value[tableId].filters = { ...filtersSlice.value[tableId].filters, ...filterData.value[data].filters, ...filterData.value[tableId]?.filters.value, [data]: filterData.value[data] }
                    }
            console.log('filtersSlice',filtersSlice);        
            isFiltersUpdated.value = true;
        } else {
            throw `centralSettingsStore.ts\\fillFiltersValue function error: tableId contain ${tableId} value. It doesn't available to use this value as key`;
        }
    };




    /** Данная функция получает все данные с сервера и отправляет промиз, который далее обрабатывается.
     * Если в нее прилетает tableId она получает данные для таблицы, в противном случае для пользователя
     * @param tableId - идентификатор текущей таблицы;*/
    const fillAllCentralSettings = async (tableId?: string) => {
        isFiltersUpdated.value = false;
        const params: ParamsType = tableId ? { tableId: tableId } : undefined;
        const settingsValues: SettingsType[] = await (async () => {
            const value = await getSettings(params);
            if (value) {
                return value;
            } else return [];
        })();
        if (settingsValues) {
            console.log('settingsValues',settingsValues);
            for (let i = 0; i < settingsValues.length; i++) settingsValues[i].value = convertFromJson(settingsValues[i].value);
            await saveCentralSettings(settingsValues, tableId);

            tableId && fillFiltersValues(tableId);

            return !tableId ? userCentralSettings.value.settings : tableCentralSettings.value.settings;
        }
        isFiltersUpdated.value = true;
        return !tableId ? userCentralSettings.value.settings : tableCentralSettings.value.settings;
    };

    /** Данная функция проеряет стейт на существование настроек и записывает их в этот стейт.
     * Если какая-то настройка уже есть в стейте, она перезаписывается на новое значение
     * @param params - объект хранящий в себе настройки определенного типа (порядок колонок/размерность таблицы/фиксирование колонок...);
     * @param tableId - идентификатор текущей таблицы;*/
    const saveTableSettings = (params: { key: string; value: any }, tableId: string | undefined) => {
        // Данная функция сохраняет данные настроек таблицы в стейт
        if (tableId && params.key.indexOf(tableId) > -1) {
            params = clearWrongDataInSettings(params, tableId);

            const value = convertFromJson(params.value);
            const data = { key: params.key, value: value };
            const propertyIndex = propertyInSettings(params, tableId);
            // Если приходит пустой объект на запись, удаляем объект из стейта
            if (!data.value && propertyIndex > -1) params.key.includes('filters_') ? tableCentralSettings.value.filters.splice(propertyIndex, 1) : tableCentralSettings.value.settings.splice(propertyIndex, 1);
            // Иначе записываем в стейт
            else if (data?.value && propertyIndex > -1) params.key.includes('filters_') ? (tableCentralSettings.value.filters[propertyIndex] = data) : (tableCentralSettings.value.settings[propertyIndex] = data);
            else if (data.value) params.key.includes('filters_') ? tableCentralSettings.value.filters.push(data) : tableCentralSettings.value.settings.push(data);
        }
    };

    const clearWrongDataInSettings = (params: { key: string; value: any }, tableId: string) => {
        if (typeof params.value !== 'object' || !params.key.includes('filters_') || !params.value.hasOwnProperty(tableId)
            || !params.value[tableId].filters.hasOwnProperty(tableId)) {
            return params;
        }

        delete params.value[tableId].filters[tableId];
        return params;
    }

    const saveObjectInTable = (data: { [K: string]: any }, tableId: string) => {
        for (const elem of Object.keys(data)) {
            const params: { key: string; value: any } = { key: '', value: undefined };
            params['key'] = elem;
            params['value'] = data[elem];
            saveTableSettings(params, tableId);
        }
    };

    const saveArrayInTable = (data: { [K: string]: any }[], tableId: string) => {
        for (const params of data) saveTableSettings({ key: params.key, value: params.value }, tableId);
    };

    /** Данная функция сохраняет данные настроек таблицы в стейт
     * @param {string} tableId - идентификатор таблицы;
     * @param data - данные которые необходимо сохранить в стейт;*/
    const saveTableCentralSettings = (tableId: string, data: { [K: string]: any }) => {
        if (tableId !== currentTableId.value) {
            tableCentralSettings.value = { settings: [], filters: [] };
            currentTableId.value = tableId;
        }
        if (!('filters' in data) || !('settings' in data)) {
            if (!Array.isArray(data)) saveObjectInTable(data, tableId);
            else saveArrayInTable(data, tableId);
        } else {
            for (const key of Object.keys(data)) {
                if (!Array.isArray(data[key])) saveObjectInTable(data[key], tableId);
                else saveArrayInTable(data[key], tableId);
            }
        }
    };

    /** Данная функция сохраняет данные настроек пользователя в стейт
     * @param data - данные которые необходимо сохранить в стейт;*/
    const saveUserCentralSettings = (data: { [K: string]: any }) => {
        const dataKeys = Object.keys(data);
        for (const key of dataKeys) {
            let index = -1;
            if (userCentralSettings.value.settings.length > 0) index = userCentralSettings.value.settings.findIndex((value) => value.key === key);
            const dataObject = { key: key, value: data[key] };
            if (index !== -1) userCentralSettings.value.settings[index] = dataObject;
            else userCentralSettings.value.settings.push(dataObject);
        }
    };

    /** Данная функция определяет по значению tableId какой тип настроек необходимо сохранить в стейте (пользовательские/таблицы)
     * @param data - данные которые будут сохраняться в стейте;
     * @param tableId - идентификатор текущей таблицы;*/
    const saveCentralSettings = async (data: { [K: string]: any }, tableId?: string) => {
        if (tableId) saveTableCentralSettings(tableId, data);
        else saveUserCentralSettings(data);
    };

    return {
        updateCentralSettings,
        fillAllCentralSettings,
        userCentralSettings,
        tableCentralSettings,
        isFiltersUpdated,
        isColumnsUpdate,
        isMounted,
        automaticThemeTimes,
        individualColorsThemes,
        isBackgroundImage
    };
});
