const DIRECTORY_STU_ROUTES = {
    path: 'directory/stu',
    name: 'directory-stu',
    redirect: { name: 'stu-list' },
    component: () => import('@/views/pages/Directories/stu/DirectoryStuPage.vue'),
    children: [
        {
            path: 'list',
            name: 'stu-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/stu/list/DirectoryStuListPage.vue')
        },
        {
            path: ':stuId',
            name: 'current-stu-stu',
            meta: { transition: 'slide-right' },
            component: () => import('@/views/pages/Directories/stu/current/DirectoryStuCurrentPage.vue'),
            children: [
                {
                    path: 'basic',
                    name: 'current-stu',
                    component: () => import('@/views/pages/Directories/stu/current/tabs/basic/tab.tsx')
                },
                {
                    path: 'additional',
                    name: 'current-stu-additional',
                    component: () => import('@/views/pages/Directories/stu/current/tabs/additional/tab.tsx')
                },
                {
                    path: 'attributes',
                    name: 'current-stu-attributes',
                    component: () => import('@/views/pages/Directories/stu/current/tabs/attributes/tab.tsx')
                },
            ]
        }
    ]
}

export default DIRECTORY_STU_ROUTES